import { template as template_c8788c8b6a654f9ebb270bf48b1f03e3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_c8788c8b6a654f9ebb270bf48b1f03e3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
