import { template as template_4b058e3f63034859b7bdd6c79a7d0009 } from "@ember/template-compiler";
const FKLabel = template_4b058e3f63034859b7bdd6c79a7d0009(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
