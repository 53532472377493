import { template as template_4fa416d490ab4ee9831b626f41b92e45 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4fa416d490ab4ee9831b626f41b92e45(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
